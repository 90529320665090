import { Suspense, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useRoutes } from 'react-router-dom'

import NProgress from 'nprogress'

import 'nprogress/nprogress.css'
import { useAuth } from '../hooks/useAuth'
import { useTranslator } from '../hooks/useTranslator'
import api from '../services/api'
import { notProtectedRoutes } from './notPrivateRoutes'
import { privateRoutes } from './privateRoutes'

export function RoutesApp() {
  const { user, signOut } = useAuth()
  const { t } = useTranslator()

  api.interceptors.response.use(
    response => response,
    error => {
      if (!error.response) {
        toast.error(t('messages.error loading'))
      } else if (error.response.status === 401) {
        signOut()
      }
      return Promise.reject(error)
    }
  )

  const router = !user ? notProtectedRoutes : privateRoutes

  const routing = useRoutes([router])

  NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })

  const LazyLoad = () => {
    useEffect(() => {
      NProgress.start()

      return () => {
        NProgress.done()
      }
    })

    return null
  }

  return <Suspense fallback={<LazyLoad />}>{routing}</Suspense>
}
