import { SignInProps as SignIn, AuthState } from './../contracts'
import api from './api'

const signInUser = async (credentials: SignIn): Promise<AuthState> => {
  const response = await api.post<AuthState>('auth', { ...credentials })

  return response.data
}

export { signInUser }
