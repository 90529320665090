import create from 'zustand'

import { TypeModalQuestion } from '../contracts'

type QuestionTypeModal = {
  question?: string
  title?: string
  buttonDecision: {
    text: string
    color?: 'error' | 'info'
  }
  keyType: TypeModalQuestion
}

type ContentModal = {
  title: string
  description: string
}

interface DialogStoreProps {
  open: boolean
  openModalError: boolean
  setOpenModalError: (open: boolean) => void
  isAgreed: boolean
  setIsAgreed: (isAgreed: boolean) => void
  setOpen: (open: boolean) => void
  contentModal: ContentModal
  setContentModal: (contentModal: ContentModal) => void
  questionModalContent: QuestionTypeModal
  setQuestionModalContent: (questionModalContent: QuestionTypeModal) => void
}

const useDialogStore = create<DialogStoreProps>(set => ({
  open: false,
  openModalError: false,
  isAgreed: false,
  setIsAgreed: isAgreed => set({ isAgreed }),
  setOpen: open => set({ open }),
  setOpenModalError: openModalError => set({ openModalError }),
  contentModal: {
    title: '',
    description: ''
  },
  setContentModal: (contentModal: ContentModal) => set({ contentModal }),
  questionModalContent: {
    question: '',
    title: '',
    buttonDecision: {
      text: '',
      color: 'info'
    },
    keyType: 'inactive'
  },
  setQuestionModalContent: (questionModalContent: QuestionTypeModal) =>
    set({ questionModalContent })
}))

export { useDialogStore }
