import { red } from '@mui/material/colors'

export const paletteLight = {
  primary: {
    main: '#070B13'
  },
  secondary: {
    main: '#E6F4FD'
  },
  error: {
    main: red.A400
  },
  background: {
    default: '#F7F9FC'
  }
}

export const paletteDark = {
  primary: {
    main: '#070B13'
  }
}
