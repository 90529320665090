import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createContext } from 'use-context-selector'

import { TranslatesContextData, TranslatesProviderProps } from '../contracts'
import i18nInitial from '../lang/i18n'

export const TranslatesContext = createContext<TranslatesContextData>(
  {} as TranslatesContextData
)

export function TranslateProvider({ children }: TranslatesProviderProps) {
  const { t, i18n } = useTranslation('', { i18n: i18nInitial })
  const [locale, setLocale] = useState<'en' | 'pt'>('en')

  async function handleChangeLanguage(location: 'en' | 'pt') {
    // if (user) {
    //   await api.patch(apiRoutes.admin.users.setLocale, { locale: location })
    // }
    setLocale(location)
    i18n.changeLanguage(location)
    localStorage.setItem('@IDaryEval:locale', location)
  }

  return (
    <TranslatesContext.Provider value={{ locale, t, handleChangeLanguage }}>
      {children}
    </TranslatesContext.Provider>
  )
}
