import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const SignIn = lazy(
  () => import(/* webpackPrefetch: true */ '../pages/Auth/SignIn')
)

const NotAuthenticatedLayout = lazy(
  () =>
    import(
      /* webpackPreloading: true */ '../components/Layouts/NotAuthenticatedLayout'
    )
)

export const notProtectedRoutes = {
  path: '/',
  element: <NotAuthenticatedLayout />,
  children: [
    {
      path: '/',
      element: <SignIn />
    },
    { path: '*', element: <Navigate to="/" /> }
  ]
}
