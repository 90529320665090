import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

type ModalQuestionProps = {
  open: boolean
  onClose: () => void
  question?: string
  title?: string
  buttonDecision?: {
    text: string
    color?: 'error' | 'info'
  }
  handleAgree: (agreed: boolean) => void
}

function ModalQuestion({
  open,
  buttonDecision = { text: 'Ok', color: 'error' },
  onClose,
  handleAgree,
  title,
  question
}: ModalQuestionProps) {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {question}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleAgree(true)}
            autoFocus
            color={buttonDecision.color}
          >
            {buttonDecision.text}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { ModalQuestion }
