import { useDialogStore } from '../../../stores/useDialogStore'
import { ModalMessage } from './ModalMessage'
import { ModalQuestion } from './ModalQuestion'

function Dialogs() {
  const {
    open: openQuestion,
    setOpen: setOpenQuestion,
    openModalError,
    setOpenModalError,
    contentModal,
    setIsAgreed,
    questionModalContent
  } = useDialogStore()

  return (
    <>
      <ModalQuestion
        open={openQuestion}
        onClose={() => setOpenQuestion(false)}
        handleAgree={setIsAgreed}
        title={questionModalContent.title}
        question={questionModalContent.question}
        buttonDecision={questionModalContent.buttonDecision}
      />
      <ModalMessage
        open={openModalError}
        onClose={() => setOpenModalError(false)}
        title={contentModal?.title}
        message={contentModal?.description}
      />
    </>
  )
}

export { Dialogs }
