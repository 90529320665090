import { useContextSelector } from 'use-context-selector'

import { AuthContextData } from '../contracts'
import { AuthContext } from '../providers/AuthProvider'

export function useAuth(): AuthContextData {
  const signIn = useContextSelector(AuthContext, state => state.signIn)
  const signOut = useContextSelector(AuthContext, state => state.signOut)
  const user = useContextSelector(AuthContext, state => state.user)

  return {
    signIn,
    signOut,
    user
  }
}
