import { useCallback, useState } from 'react'

import { createContext } from 'use-context-selector'

import {
  IUser,
  SignInProps as SignIn,
  AuthState,
  AuthContextData
} from '../contracts'
import { signInUser } from '../services'
import api from '../services/api'

export const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@IDaryEval:token')
    const user = localStorage.getItem('@IDaryEval:user')
    const expiresIn = localStorage.getItem('@IDaryEval:expiresIn')

    if (token && user && expiresIn) {
      const expirationDate = new Date(expiresIn)

      if (expirationDate > new Date()) {
        api.defaults.headers.common.Authorization = `Bearer ${token}`

        return { token, user: JSON.parse(user) as IUser, expires_at: expiresIn }
      } else {
        localStorage.removeItem('@IDaryEval:token')
        localStorage.removeItem('@IDaryEval:user')
        localStorage.removeItem('@IDaryEval:expiresIn')
      }
    }

    return {} as AuthState
  })

  const signIn = useCallback(async ({ email, password }: SignIn) => {
    const response = await signInUser({ email, password })

    const { token, user, expires_at } = response

    localStorage.setItem('@IDaryEval:token', token)
    localStorage.setItem('@IDaryEval:user', JSON.stringify(user))
    localStorage.setItem('@IDaryEval:expiresIn', expires_at)

    api.defaults.headers.common.Authorization = `Bearer ${token}`

    setData({ token, user, expires_at })
  }, [])

  const signOut = useCallback(() => {
    localStorage.removeItem('@IDaryEval:token')
    localStorage.removeItem('@IDaryEval:user')
    localStorage.removeItem('@IDaryEval:expiresIn')

    setData({} as AuthState)
  }, [])

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}
