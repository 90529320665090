import { ReactNode } from 'react'

import { Dialogs } from '../components/UI/Modal'
import { AuthProvider } from './AuthProvider'
import { ThemeProviderCustom } from './ThemeProvider'
import { TranslateProvider } from './TranslateProvider'

interface AppProviderProps {
  children: ReactNode
}

export function AppProvider({ children }: AppProviderProps) {
  return (
    <>
      <Dialogs />
      <AuthProvider>
        <TranslateProvider>
          <ThemeProviderCustom>{children}</ThemeProviderCustom>
        </TranslateProvider>
      </AuthProvider>
    </>
  )
}
