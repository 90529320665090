import { useContextSelector } from 'use-context-selector'

import { TranslatesContextData } from '../contracts'
import { TranslatesContext } from '../providers/TranslateProvider'

export function useTranslator(): TranslatesContextData {
  const t = useContextSelector(TranslatesContext, state => state.t)
  const locale = useContextSelector(TranslatesContext, state => state.locale)
  const handleChangeLanguage = useContextSelector(
    TranslatesContext,
    state => state.handleChangeLanguage
  )

  return {
    t,
    locale,
    handleChangeLanguage
  }
}
