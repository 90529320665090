import { ToastContainer } from 'react-toastify'

import { AppProvider } from './providers'
import { RoutesApp } from './routes'
import 'react-toastify/dist/ReactToastify.css'

export function App() {
  return (
    <>
      <AppProvider>
        <RoutesApp />
        <ToastContainer />
      </AppProvider>
    </>
  )
}
