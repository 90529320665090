import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import En from './en.json'
import Pt from './pt.json'

const resources = {
  en: {
    translation: En
  },
  pt: {
    translation: Pt
  }
}
i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false
  }
})
export default i18n
