import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'
import { Toaster } from 'react-hot-toast'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {
  createTheme,
  CssBaseline,
  Theme,
  ThemeProvider,
  useMediaQuery
} from '@mui/material'
import prBrLocale from 'date-fns/locale/pt-BR'

import { paletteLight, paletteDark } from '../theme/palette'

interface ThemeProviderProps {
  children: ReactNode
}

export interface ThemeProviderData {
  theme: Theme
  handleChangeDarkMode(): void
  prefersDarkMode: boolean
}

export const ThemeContext = createContext<ThemeProviderData>(
  {} as ThemeProviderData
)

export function ThemeProviderCustom({ children }: ThemeProviderProps) {
  const darkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const [prefersDarkMode, setPrefersDarkMode] = useState(darkMode)

  const themeSelect = useMemo(() => {
    const paletteDefault = prefersDarkMode ? paletteDark : paletteLight

    return createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
        ...paletteDefault
      }
    })
  }, [prefersDarkMode])

  const handleChangeDarkMode = useCallback(() => {
    setPrefersDarkMode(!prefersDarkMode)
  }, [prefersDarkMode])

  return (
    <ThemeContext.Provider
      value={{ theme: themeSelect, handleChangeDarkMode, prefersDarkMode }}
    >
      <ThemeProvider theme={themeSelect}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={prBrLocale}>
          <ThemeProvider theme={themeSelect}>{children}</ThemeProvider>
          <Toaster
            position="top-right"
            toastOptions={{
              success: {
                style: {
                  background: `${themeSelect.palette.success.main}`,
                  color: '#fff',
                  fontFamily: `${themeSelect.typography.fontFamily}`
                },
                duration: 3000
              },
              error: {
                style: {
                  background: `${themeSelect.palette.error.main}`,
                  color: '#fff'
                },
                duration: 5000
              }
            }}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}
