import { lazy } from 'react'

const Accounts = lazy(
  () => import(/* webpackPrefetch: true */ '../pages/Authenticated/Accounts')
)

const AuthenticatedLayout = lazy(
  () =>
    import(
      /* webpackPreloading: true */ '../components/Layouts/AuthenticatedLayout'
    )
)

export const privateRoutes = {
  path: '/',
  element: <AuthenticatedLayout />,
  children: [
    {
      path: '/',
      element: <Accounts />
    },
    { path: '*', element: <Accounts /> }
  ]
}
